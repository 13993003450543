<template>
  <div class="z-10 top-0 sticky body__header border px-5 py-2">
    <div
      v-if="this.ticketId === null"
      class="sm:items-center top-0 flex flex-wrap sm:justify-between"
    >
      <!-- <div class="flex flex-wrap sm:justify-center w-10/12 sm:w-auto">
        
        <SearchFilter
          v-model:search="search"
          @input="$emit('update:search', this.search)"
          :inputPlaceholder="$t('header.searchTicketPlaceHolder')"
        />
      </div> -->
      <div class="xs:mt-0 lg:mt-0">
        <button
          @click.prevent="this.sendOpenForm()"
          :class="[
            open ? 'text-white' : 'text-white',
            'rounded group sm:text-sm text-md body__header--button inline-flex items-center font-medium p-2 mr-0',
          ]"
        >
          <span class="text-bold uppercase hidden md:block ">{{
            $t("header.openTicket")
          }}</span>
          <span class="md:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
          </span>
        </button>
      </div>
    </div>
    <div v-else class="sm:items-center top-0 flex flex-wrap justify-between">
      <nav class="flex" aria-label="Breadcrumb">
        <ol role="list" class="flex items-center space-x-4">
          <li>
            <div>
              <a
                @click="this.$router.push({ path: '/' })"
                class="text-gray-400 hover:text-gray-500 cursor-pointer"
              >
                <HomeIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                <span class="sr-only">Home</span>
              </a>
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <ChevronRightIcon
                class="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <a
                @click="this.$emit('ticket-id', null)"
                title="Go back list tickets"
                class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer"
                >{{ $t("ticketTable.ticketList") }}</a
              >
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <ChevronRightIcon
                class="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <a
                class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer"
                >Ticket #{{ this.ticketId }}</a
              >
            </div>
          </li>
        </ol>
      </nav>
      <button
        type="button"
        @click="this.$emit('ticket-id', null)"
        title="Close ticket window"
        class="inline-flex items-center p-1 border border-transparent rounded-md text-gray-600 hover:bg-gray-200 focus:outline-none"
      >
        <XIcon class="h-4 w-4" aria-hidden="true" />
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { FilterIcon, SearchIcon } from "@heroicons/vue/outline";

import { ChevronRightIcon, HomeIcon, XIcon } from "@heroicons/vue/solid";
// import SearchFilter from "../components/SearchFilter.vue";

import { mapGetters } from "vuex";
const pages = [
  { name: "Projects", href: "#", current: false },
  { name: "Project Nero", href: "#", current: true },
];
export default {
  name: "TicketsHeader",
  props: ["searchStatus", "ticketId"],
  components: {
    FilterIcon,
    SearchIcon,
    ChevronRightIcon,
    HomeIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    XIcon,
  },
  setup() {
    return {
      pages,
    };
  },
  data() {
    return {
      search: "",
      closeForm: false,
      onFocus: "Search by subject, created date or updated date",
      searchDate: "",
      searchDateUpdate: "",
      searchStatus: [],
      status: [],
    };
  },
  methods: {
    sendOpenForm() {
      this.$emit("openForm");
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    pushSearchStatus(status) {
      if (!this.searchStatus.includes(status)) {
        this.searchStatus.push(status);
      } else {
        let index = this.searchStatus.indexOf(status);
        if (index > -1) {
          this.searchStatus.splice(index, 1);
        }
      }
      this.$emit("pushStatusSearch", status);
    },
    async getStatus() {
      const res = await axios.get(
        `${this.$cookie.getCookie("API")}/api/v1/statuses`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      this.status = res.data;
    },
  },
  mounted() {
    this.getStatus();
  },
};
</script>

<style></style>
