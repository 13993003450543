<template>
 <!-- Table -->
 <div
  v-if="this.filteredTickets().length > 0"
  class="bg-white shadow overflow-y-auto overflow-x-hidden sm:rounded max-h-screen"
 >
  <ul role="list" class="divide-y divide-gray-200">
   <li
    v-for="ticket in this.filteredTickets()"
    :key="ticket.id"
    v-show="ticket.open || searchStatus.includes('closed')"
   >
    <a
     href="javascript:void(0)"
     :class="[this.ticketId == ticket.id ? 'bg-gray-200' : 'bg-white', 'block hover:bg-gray-50']"
     @click="goToDetail(ticket.id, ticket.status_id)"
    >
     <div class="px-2 py-3">
      <div class="flex items-center justify-between">
       <div class="inline-flex truncate">
        <p :title="ticket.subject" class="text-sm font-medium encom_dark_text truncate">
         {{ ticket.subject }}
         <span class="flex items-center text-xs text-gray-500 sm:mt-0">
          <CalendarIcon class="flex-shrink-0 mr-0.5 h-4 w-4 text-gray-400" aria-hidden="true" />
          <time class="text-xs mt-0.5">{{ formatDate(ticket.created_at) }}</time>
         </span>
        </p>
       </div>

       <div class="ml-2 flex-shrink-0 flex" v-if="ticket.open">
        <span
         :class="{
          encom_ouvert_ticket_new: ticket.status_id == 1,
          encom_en_traitement_ticket_new: ticket.status_id == 2,
          encom_en_attente_new: ticket.status_id == 3,
          encom_autre_ticket_new: ticket.status_id == 4,
         }"
         class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium"
        >
         {{ checkStatus(ticket.status_id) }}
        </span>
        <!--  <p
         :class="[
          ticket.status_id == 1
           ? `encom_ouvert_ticket`
           : ticket.status_id == 2
           ? `encom_en_traitement_ticket`
           : ticket.status_id == 3
           ? `encom_pink_bg`
           : `encom_closed_ticket`,
          'text-white px-2 inline-flex text-xs leading-5 uppercase rounded-full',
         ]"
        >
         {{ this.checkStatus(ticket.status_id) }}
        </p> -->
       </div>
       <div class="ml-2 flex-shrink-0 flex" v-else>
        <p
         :class="[
          'encom_closed_ticket text-white px-2 inline-flex text-xs leading-5 uppercase rounded-full',
         ]"
        >
         {{ $t("closed") }}
        </p>
       </div>
      </div>
     </div>
    </a>
   </li>
  </ul>
 </div>
 <div v-else class="text-center">
  <svg
   class="mx-auto h-16 w-16 text-gray-400"
   fill="none"
   viewBox="0 0 24 24"
   stroke="currentColor"
   aria-hidden="true"
  >
   <path
    vector-effect="non-scaling-stroke"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
   />
  </svg>
  <h3 class="mt-2 text-sm font-medium text-gray-900">
   {{ $t("warning.noDataFoundTitle") }}
  </h3>
  <p class="mt-1 text-sm text-gray-500">
   {{ $t("warning.noResultFoundDescription") }}
  </p>
 </div>
</template>

<script>
import axios from "axios";

import { CalendarIcon, LocationMarkerIcon, UsersIcon } from "@heroicons/vue/solid";

const account =
 localStorage.getItem("account") === ""
  ? this.$store.state.user.activeAccount
  : localStorage.getItem("account");

const months = [
 "January",
 "February",
 "March",
 "April",
 "May",
 "June",
 "July",
 "August",
 "September",
 "October",
 "November",
 "December",
];

export default {
 props: ["ticketId", "search", "searchStatus"],
 components: {
  CalendarIcon,
  LocationMarkerIcon,
  UsersIcon,
 },
 data() {
  return {
   account,
   months,
   status: [],
   tickets: [],
   updateTicket: false,
  };
 },
 methods: {
  async getTickets() {
   const res = await axios.get(
    `${this.$cookie.getCookie("API")}/api/v1/tickets?customerAccount=${account}`,
    {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    }
   );

   this.isLoading = false;
   this.tickets = this.orderArray(res.data);
  },
  orderArray(arr) {
   return arr.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
   });
  },
  filteredTickets() {
   return this.tickets.filter((ticket) => {
    if (this.searchStatus.length > 0) {
     let status = this.checkStatus(ticket.status_id);
     if (this.search) {
      return (
       ticket.subject.toLowerCase().includes(this.search.toLowerCase()) ||
       ticket.id == this.search ||
       this.formatDate(ticket.created_at).toLowerCase().includes(this.search.toLowerCase()) ||
       this.formatDate(ticket.updated_at).toLowerCase().includes(this.search.toLowerCase())
      );
     }
     if (this.searchStatus.includes("closed")) {
      return !ticket.open;
     } else {
      return this.searchStatus.includes(status);
     }
    } else if (this.search) {
     return (
      ticket.subject.toLowerCase().includes(this.search.toLowerCase()) ||
      ticket.id == this.search ||
      this.formatDate(ticket.created_at).toLowerCase().includes(this.search.toLowerCase()) ||
      this.formatDate(ticket.updated_at).toLowerCase().includes(this.search.toLowerCase())
     );
    }
    return ticket;
   });
  },
  goToDetail(id, status) {
   let statusName = this.checkStatus(status);
   this.$emit("updateTicketDetail", id, statusName);
   this.$forceUpdate();
  },
  formatDate(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear();

   return formatedDate;
  },

  async getStatus() {
   const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/statuses`, {
    headers: {
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   });
   this.status = res.data;
  },
  checkStatus(id) {
   if (this.status) {
    for (let index = 0; index < this.status.length; index++) {
     if (this.status[index].id == id) {
      return this.status[index].name;
     }
    }
   }
  },
 },
 mounted() {
  this.getTickets();
  this.getStatus();
 },
};
</script>

<style></style>
